import { createRouter, createWebHistory } from 'vue-router'


const routes = [
    // 首页
    {
        path: '/',
        name: 'home',
        component: () => import('../views/HomeView.vue'),
    },
    // 客服端
    {
        path: '/service',
        name: 'service',
        component: () => import('../views/serviceView.vue'),
        children: [
            {
                path: '',
                name: "ControlView",
                component: () => import('../components/SingleMenuView/ControlView.vue'),
            },
            {
                path: 'setting',
                component: () => import('../components/SingleMenuView/SettingView.vue'),
            },
            {
                path: 'charge',
                component: () => import('../components/SingleMenuView/ChargeView.vue'),
            },
            // 线索管理  ---线索列表
            {
                path: 'clues',
                name: 'clues',
                component: () => import('../components/LeadManagement/CluesView.vue'),
                meta: { keepAlive: true }
            },
            // 跟进详情
            {
                path: 'management',
                name: 'management',
                component: () => import('../components/Thousand/ManagementView.vue'),
            },
            // 申请审核列表
            {
                path: 'leadapproval',
                component: () => import('../components/LeadManagement/LeadApproval.vue'),
            },
            // 审批管理详情
            {
                path: 'leadapprovaldetail/:id',
                component: () => import('../components/LeadManagement/components/LeadApprovalElement/LeadApprovalDetail.vue'),
            },
            // 交易列表
            {
                path: 'trade',
                component: () => import('../components/LeadManagement/TransactionView.vue'),
            },
            //线索公海
            {
                path: 'sea',
                component: () => import('../components/LeadManagement/SeaView.vue'),
            },
            //线索公海  新建用户
            {
                path: 'new',
                component: () => import('../components/LeadManagement/components/SeaElement/NewView.vue'),
            },
            //数据分析
            // {
            //     path: 'analyse',
            //     component: () => import('../components/LeadManagement/AnalyseView.vue'),
            // },
            //学员列表
            {
                path: 'traineelist',
                component: () => import('../components/LeadManagement/TraineelistView.vue'),
            },
            // 分期数据
            {
                path: 'stagingData',
                component: () => import('../components/LeadManagement/StagingData.vue'),
            },
            // 分期数据查看详情
            {
                path: 'ViewDetails/:id/:tab/:thread_id?/:source_id',
                component: () => import('../components/Staging/ViewDetails.vue'),
            },
            // 催收列表
            {
                path: 'Collection',
                component: () => import('../components/LeadManagement/Collection.vue'),
            },
            //催收列表详情
            {
                path: 'Collecdetail/:id/:thread_id',
                component: () => import('../components/LeadManagement/components/CollectionElement/detail.vue'),
            },
            // 线索管理  退学列表
            {
                path: 'DropOut',
                component: () => import('../components/LeadManagement/DropOutView.vue'),
            },
            // 阶段交易列表
            // {
            //     path: 'StageTransactionList',
            //     component: () => import('../components/LeadManagement/StageTransactionView.vue'),
            // },
            // 分阶段学员
            // {
            //     path: 'PhasedTraine',
            //     component: () => import('../components/LeadManagement/PhasedTraine.vue'),
            // },
            // 分阶段学员详情
            {
                path: 'Phasedtrainee/:id',
                component: () => import('../components/LeadManagement/components/PhasedtraineeElement/Phasedtrainee.vue'),
            },
            // 权限管理 ---账号管理
            {
                path: 'manage',
                component: () => import('../components/Account/manage.vue'),
            },
            // 组织架构
            {
                path: 'team',
                component: () => import('../components/Account/TeamView.vue'),
            },
            // 投诉管理--投诉列表
            {
                path: 'complaints',
                component: () => import('../components/complaints/Schedule.vue'),
            },
            // 投诉管理--客诉投诉
            {
                path: 'CustomerComplaints',
                component: () => import('../components/complaints/CustomerComplaints.vue'),
            },
            // 审核管理 --补录审核
            // {
            //     path: 'audit',
            //     component: () => import('../components/AuditManagement/manage.vue'),
            // },
            // 分期补缴审核
            {
                path: 'Installments',
                name: 'Installments',
                component: () => import('../components/AuditManagement/Installments.vue'),
            },
            // 待审核-查看详情
            {
                path: 'InstallDetails/:id/:detail_type',
                component: () => import('../components/AuditManagement/components/InstallmentsElement/InstallDetails.vue'),
            },
            // 消款审核
            {
                path: 'CancellationS',
                component: () => import('../components/AuditManagement/CancellationS.vue'),
            },
            //消款详情
            {
                path: 'CancellDetails/:id',
                component: () => import('../components/AuditManagement/components/CancellationSElement/CancellDetails.vue'),
            },
            // 消款补缴
            {
                path: 'EliminationDetail/:id',
                component: () => import('../components/AuditManagement/components/CancellationSElement/EliminationDetail.vue'),
            },
            // 退学审核
            // {
            //     path: 'Withdrawal',
            //     name: 'Withdrawal',
            //     component: () => import('../components/AuditManagement/Withdrawal.vue'),
            // },
            // 审核列表
            {
                path: 'auditlist',
                component: () => import('../components/AuditManagement/AuditList.vue'),
            },
            //财务管理 --订单列表
            {
                path: 'Orders',
                component: () => import('../components/Finance/Orders.vue'),
            },
            // 退单列表
            {
                path: 'chargebackOrders',
                component: () => import('../components/Finance/chargebackOrders.vue')
            },
            // 打款列表
            {
                path: 'ParttimePayment',
                component: () => import('../components/Finance/ParttimePayment.vue')
            },
            // 提现列表
            {
                path: 'ParttimeWithdrawal',
                component: () => import('../components/Finance/ParttimeWithdrawal.vue')
            },
            // 财务管理  退款审核
            {
                path: 'Refund',
                component: () => import('../components/Finance/Refund.vue'),
            },
            // 分期平台订单
            {
                path: 'PlatOrders',
                component: () => import('../components/Finance/PlatOrders.vue'),
            },
            // 财务管理 发票管理
            {
                path: 'invoiceView',
                component: () => import('../components/Finance/invoiceView.vue')
            },
            // 财务管理 发票跟进详情
            {
                path: 'invoiceDetile/:list/:deti_type',
                component: () => import('../components/Finance/invoiceDetile.vue')
            },
            // 分期平台订单详情
            {
                path: 'Ordersdetail/:id?',
                component: () => import('../components/Finance/Ordersdetail.vue'),
            },
            // 兼职管理--兼职商户列表
            {
                path: 'parttimeMerchant',
                component: () => import('../components/PartTimeManagement/parttimeMerchantView.vue'),
            },
            // 兼职管理--商户充值记录
            {
                path: 'merchantRechargeRecords',
                component: () => import('../components/PartTimeManagement/merchantRechargeRecords.vue'),
            },
            // 兼职管理--兼职列表
            {
                path: 'parttimeList',
                component: () => import('../components/PartTimeManagement/parttimeList.vue'),
            },
            // 兼职管理 -- 新建兼职
            {
                path: 'newPartTime/:isAdd/:id/:type',
                component: () => import('../components/PartTimeManagement/components/ParttimeMerchantElement/NewPartTimeView.vue'),
            },
            // 兼职管理 -- 兼职学员列表
            {
                path: 'partStutentsList',
                component: () => import('../components/parTimeManstatus/partStutentsList.vue'),
            },
            // 兼职管理 -- 报名用户
            {
                path: 'registeredUsers/:id/:type',
                component: () => import('../components/PartTimeManagement/registeredUsersView.vue'),
            },
            // 报名用户 查看详情
            {
                path: 'registeredUsersDetails/:id/:is_processed/:is_cancel/:is_unqualified/:is_can_pay/:is_pay/:collocation_task_id',
                component: () => import('../components/PartTimeManagement/registeredUsersDetails.vue'),
            },
            // 教学管理--课程管理
            {
                path: 'courseManage',
                component: () => import('../components/coursePages/courseManage.vue'),
            },
            // 课表管理
            {
                path: 'Schedule',
                component: () => import('../components/coursePages/Schedule.vue'),
            },
            // 新建课程
            {
                path: 'newconStruction',
                component: () => import('../components/coursePages/newconStruction.vue')
            },
            // 编辑课程
            {
                path: 'Editingcourse',
                component: () => import('../components/coursePages/Editingcourse.vue')
            },
            // 新建课表
            {
                path: 'newSchedule',
                component: () => import('../components/coursePages/newSchedule.vue'),
            },
            // 查看课表
            {
                path: 'ViewSchedule/:id/:stage_info',
                component: () => import('../components/coursePages/ViewSchedule.vue'),
            },
            // 关联视频
            {
                path: 'Relevancyrcy/:id',
                component: () => import('../components/coursePages/Relevancy.vue'),
            },
            // 生成课表
            {
                path: 'generateSche/:list',
                component: () => import('../components/coursePages/generateSchedule.vue'),
            },
            // 报名学员管理
            {
                path: 'NewStudentSmanage',
                component: () => import('../components/coursePages/NewStudentSmanage.vue'),
            },
            // 教学学员管理
            {
                path: 'TeachingManage',
                component: () => import('../components/coursePages/TeachingManage.vue'),
            },
            // 教学学员管理>>跟进
            {
                path: 'StudentInfo',
                component: () => import('../components/coursePages/StudentInfo.vue'),
            },
            // 导入直播数据
            {
                path: 'LiveBroadcast',
                component: () => import('../components/coursePages/components/TeachingManageElement/LiveBroadcast.vue')
            },
            // 教学审批管理
            {
                path: 'ApprovalManage',
                component: () => import('../components/coursePages/ApprovalManage.vue'),
            },
            // 视频资源管理
            {
                path: 'VideoResources',
                component: () => import('../components/coursePages/VideoResources.vue'),
            },
            // 题库资源管理
            {
                path: 'QuestionBank',
                component: () => import('../components/coursePages/QuestionBank.vue'),
            },
            // 新建题库
            {
                path: 'newQuestionb/:id/:type?',
                component: () => import('../components/coursePages/components/QuestionBankElement/newQuestionb.vue'),
            },
            // 试卷资源
            {
                path: 'PaperSche',
                component: () => import('../components/coursePages/PaperSche.vue'),
            },
            // 新建试卷
            {
                path: 'NewPaper/:id?',
                component: () => import('../components/coursePages/components/PaperScheElement/NewPaper.vue'),
            },
            // 集训实战营
            {
                path: 'TrainingCamp',
                component: () => import('../components/coursePages/TrainingCamp.vue'),
            },
            // 集训实战营 查看课表
            {
                path: 'TrainingCampViewCourse/:course_id/:m_course_id/:e_course_id',
                component: () => import('../components/coursePages/components/TrainingCampElement/TrainingCampViewCourse.vue'),
            },
            // 集训实战营新增讲次
            {
                path: 'TrainingCampAddLecture/:course_id/:m_course_id/:e_course_id',
                component: () => import('../components/coursePages/components/TrainingCampElement/TrainingCampAddLecture.vue'),
            },
            // 学期管理
            {
                path: 'semester',
                component: () => import('../components/Paper/semester.vue'),
            },
            // 发布承诺书
            {
                path: 'release/:id',
                component: () => import('../components/Paper/Release.vue'),
            },
            // 添加学员
            {
                path: 'Viewsemester',
                component: () => import('../components/Paper/Viewsemester.vue'),
            },
            // 发布作业
            {
                path: 'homework/:id',
                component: () => import('../components/Paper/homework.vue'),
            },
            // 发布考试
            {
                path: 'Examination/:id',
                component: () => import('../components/Paper/Examination.vue'),
            },
            // 批阅管理
            {
                path: 'Marking',
                component: () => import('../components/Marking/Schedule.vue'),
            },
            // 批阅作业
            {
                path: 'Assignview/:id',
                component: () => import('../components/Marking/Assignview.vue'),
            },
            // 批阅考试
            {
                path: 'Paperview/:id',
                component: () => import('../components/Marking/Paperview.vue'),
            },
            // 欠款平台
            {
                path: 'debt',
                component: () => import('../components/NotUse/DebtView.vue'),
            },
            // 订单详情
            {
                path: 'detail',
                name: 'detail',
                component: () => import('../components/NotUse/DetailView.vue'),
            },
            // 主题管理
            {
                path: 'theme',
                component: () => import('../components/Forum/ThemeView.vue'),
            },
            // 作品列表
            {
                path: 'worksList',
                component: () => import('../components/Forum/WorksListView.vue'),
            },
            // 话题列表
            {
                path: 'topicsList',
                component: () => import('../components/Forum/topicsListView.vue'),
            },
            // 话题类型创建
            {
                path: 'topicsType',
                component: () => import('../components/Forum/topicsType.vue'),
            },
            // 发布审核
            {
                path: 'releaseReview',
                component: () => import('../components/Forum/ReleaseReviewView.vue'),
            },
            //论坛管理--问题标签
            {
                path: 'problemLabel',
                component: () => import('../components/Forum/ProblemLabel.vue'),
            },
            //问题管理
            {
                path: 'problemManage',
                component: () => import('../components/Forum/ProblemManage.vue'),
            },
            // 匿名回答
            {
                path: 'anonymity',
                component: () => import('../components/Forum/anonymityView.vue'),
            },
            // 匿名回答详情页
            {
                path: 'anonymitdetail/:id?',
                component: () => import('../components/Forum/anonymitdetailView.vue')
            },
            // 问题列表详情
            {
                path: 'problemDetail/:id?/:isAnswer?',
                component: () => import('../components/Forum/ProblemDetail.vue'),
            },
            // 问题新增
            {
                path: 'problemNew/:id?',
                component: () => import('../components/Forum/ProblemNew.vue'),
            },
            // 评论审核
            {
                path: 'commentList',
                component: () => import('../components/Forum/commentListView.vue'),
            },
            // 设置管理
            {
                path: 'Settings',
                component: () => import('../components/Forum/Settings.vue'),
            },
            {
                path: 'EmoSeekHelp',
                component: () => import('../components/Forum/emoSeekHelp.vue')
            },
            // 帖子管理
            {
                path: 'PostMana',
                component: () => import('../components/Forum/PostMana.vue'),
            },
            //系统设置--用户标签设置
            {
                path: 'usertag',
                component: () => import('../components/SystemSettings/UserTagView.vue'),
            },
            // 跟进动作设置
            {
                path: 'follow',
                component: () => import('../components/SystemSettings/FollowView.vue'),
            },
            // 用户等级管理
            {
                path: 'grade',
                component: () => import('../components/SystemSettings/GradeView.vue'),
            },
            // 多标签设置
            {
                path: 'labeling',
                component: () => import('../components/SystemSettings/MultiLabeling.vue'),
            },
            //线索来源
            {
                path: 'origin',
                component: () => import('../components/SystemSettings/OriginView.vue'),
            },
            //用户表单
            {
                path: 'userform',
                component: () => import('../components/SystemSettings/Userform.vue'),
            },
            //流失原因
            {
                path: 'reason',
                component: () => import('../components/SystemSettings/ReasonView.vue'),
            },
            // 用户资料管理
            {
                path: 'file',
                component: () => import('../components/SystemSettings/FileView.vue'),
            },
            // 试听课程管理
            // {
            //     path: 'course',
            //     component: () => import('../components/SystemSettings/CourseView.vue'),
            // },
            // 报名缴费管理
            // {
            //     path: 'cost',
            //     component: () => import('../components/SystemSettings/CostView.vue'),
            // },
            // 保证书模板
            {
                path: 'guarantee',
                component: () => import('../components/SystemSettings/GuaranteeView.vue'),
            },
            //兼职提现设置
            {
                path: 'PartTimeWithdrawalSetting',
                component: () => import('../components/SystemSettings/PartTimeWithdrawalSetting.vue'),
            },
            //线索设置
            {
                path: 'culeSetting',
                component: () => import('../components/SystemSettings/CuleSetting.vue'),
            },
            // 脱敏列表
            {
                path: 'desensitization',
                component: () => import('../components/SystemSettings/desensitizationView.vue'),
            },
            // 脱敏列表查看1
            {
                path: 'desensitizationdetails/:id?',
                component: () => import('../components/SystemSettings/components/DesensitizationElement/desensitizationdetailsView.vue'),
            },
            // 脱敏列表查看2
            {
                path: 'Teachersaccountdetails/:id?',
                component: () => import('../components/SystemSettings/components/DesensitizationElement/Teachersaccountdetails.vue'),
            },
            // 脱敏列表查看3
            {
                path: 'Merchantdetails/:id?',
                component: () => import('../components/SystemSettings/components/DesensitizationElement/Merchantdetails.vue'),
            },
            // 异动标签设置
            {
                path: 'labelSetting',
                component: () => import('../components/SystemSettings/LabelSetting.vue'),
            },
            // 分期平台设置
            {
                path: 'stagingPlatform',
                component: () => import('../components/SystemSettings/StagingPlatform.vue'),
            },
            // 催收标签
            {
                path: 'collectionLabel',
                component: () => import('../components/SystemSettings/CollectionLabel.vue'),
            },
            // 审核设置
            {
                path: 'auditSetting',
                component: () => import('../components/SystemSettings/AuditSetting.vue'),
            },
            // 用户协议
            {
                path: 'userAgreement',
                component: () => import('../components/SystemSettings/UserAgreement.vue'),
            },
            // 隐私协议
            {
                path: 'privacyAgreement',
                component: () => import('../components/SystemSettings/PrivacyAgreement.vue'),
            },
            // 个人隐私信息收集声明
            {
                path: 'personalPrivacy',
                component: () => import('../components/SystemSettings/PersonalPrivacy.vue'),
            },
            {
                path: 'PaymentAgreement',
                component: () => import('../components/SystemSettings/PaymentAgreement.vue'),
            },
            // 数据统计
            {
                path: 'CheckAttendance',
                component: () => import('../components/DataStatistics/CheckAttendance.vue')
            },
            {
                path: 'Different',
                component: () => import('../components/DataStatistics/Different.vue')
            },
            // 补缴详情
            {
                path: 'Supplementary/:id/:name',
                component: () => import('../components/Staging/Supplementary.vue'),
            },
            {
                path: 'DropOutDetails/:id/:detail_type',
                component: () => import('../components/Staging/DropOutDetails.vue'),
            },
            // 台账管理--台账数据
            {
                path: 'ledger',
                component: () => import('../components/ledger/ledger.vue'),
            },
            // 台账数据回收站
            {
                path: 'recyclebin',
                component: () => import('../components/ledger/recyclebin.vue'),
            },
            // 分期明细
            {
                path: 'StagingDetail',
                component: () => import('../components/ledger/StagingDetail.vue'),
            },
            // 导入数据
            {
                path: 'ImprotData',
                component: () => import('../components/ledger/ImproData.vue'),
            },
            {
                path: 'php',
                component: () => import('../views/phpView.vue'),
            },
            // 用户体系  勋章管理
            {
                path: 'medalManage',
                component: () => import('../components/userSystem/medalManage.vue')
            },
            // 兑换商城
            {
                path: 'conversionShop',
                component: () => import('../components/userSystem/conversionShop.vue')
            },
            // 积分奖励
            {
                path: 'integralAward',
                component: () => import('../components/userSystem/integralAward.vue')
            },
            // 积分抽奖
            {
                path: 'integralDraw',
                component: () => import('../components/userSystem/integralDraw.vue')
            },
            // 兑换记录
            {
                path: 'conversionRecord',
                component: () => import('../components/userSystem/conversionRecord.vue')
            },
            // 内容管理
            {
                path: 'contentMange',
                component: () => import('../components/userSystem/contentMange.vue')
            },
            // 每日签到
            {
                path: 'dailyCheckIn',
                component: () => import('../components/userSystem/DailyCheckIn.vue')
            },
            // 新建系列
            {
                path: 'newMedal',
                component: () => import('../components/userSystem/newMedal.vue')
            },
            // 新建商品
            {
                path: 'newCommodity',
                component: () => import('../components/userSystem/newCommodity.vue')
            },
            // 新建商品
            {
                path: 'Newdraw',
                component: () => import('../components/userSystem/Newdraw.vue')
            },
            // 发布文章
            {
                path: 'publishBook',
                component: () => import('../components/userSystem/publishBook.vue')
            },
            // 目标管理  班主任目标设置
            {
                path: 'teacheTarget',
                component: () => import('../components/TargetManagement/teacheTargetview.vue'),
            },
            // 目标管理  薪资绩效设置
            {
                path: 'salary',
                component: () => import('../components/TargetManagement/salary.vue'),
            },
            // 播报设置
            {
                path: 'broadcast',
                component: () => import('../components/TargetManagement/BroadcastView.vue'),
            },
            // 兼职商户设置
            {
                path: 'AccountSetup',
                component: () => import('../components/AccountSetup/AccountSetup.vue'),
            },
            // 新建商户
            {
                path: 'NewlyBuiltMerchant/:id?',
                component: () => import('../components/PartTimeManagement/NewlyBuiltMerchant.vue'),
            },

            // 课程付费--课程
            {
                path: 'coursePay',
                component: () => import('../components/CoursePayment/coursePayment.vue'),
            },
            // 新建课程
            {
                path: 'newCourse',
                component: () => import('../components/CoursePayment/components/coursePayment/newCourse.vue'),
            },
            // 编辑课程
            {
                path: 'editCourse',
                component: () => import('../components/CoursePayment/components/coursePayment/editCourse.vue'),
            },
            // 跟进课程
            {
                path: 'followUp',
                component: () => import('../components/CoursePayment/components/coursePayment/followUp.vue'),
            },
            // 课程评价
            {
                path: 'courseEvaluation',
                component: () => import('../components/CoursePayment/components/coursePayment/courseEvaluation.vue'),
            },
            // 渠道配置
            {
                path: 'channelConfig',
                component: () => import('../components/CoursePayment/components/coursePayment/channelConfig.vue'),
            },
            // 课程付费--订单
            {
                path: 'courseOrders',
                component: () => import('../components/CoursePayment/orders.vue'),
            },
            // 课程付费--优惠券
            {
                path: 'courseCoupons',
                component: () => import('../components/CoursePayment/coupons.vue'),
            },
            // 新建优惠券
            {
                path: 'createANewCoupon',
                component: () => import('../components/CoursePayment/components/coupons/createANewCoupon.vue'),
            },
            // 编辑优惠券
            {
                path: 'editCoupon',
                component: () => import('../components/CoursePayment/components/coupons/editCoupon.vue'),
            },
            // 课程付费--讲师资源
            {
                path: 'lecturerResources',
                component: () => import('../components/CoursePayment/lecturerResources.vue'),
            },
            // 课程付费--大课配置
            {
                path: 'majorCoursesList',
                component: () => import('../components/CoursePayment/majorCoursesList.vue'),
            },
            {
                path: 'majorCoursesConfiguration',
                component: () => import('../components/CoursePayment/majorCoursesConfiguration.vue'),
            },
            // 课程付费--用户注册列表
            {
                path: 'userRegistrationList',
                component: () => import('../components/CoursePayment/userRegistrationList.vue'),
            },
            // 用户注册详情
            {
                path: 'userRegisterDetail',
                component: () => import('../components/CoursePayment/components/userRegister/userRegisterDetail.vue'),
            },
            // 课程付费--充值列表
            {
                path: 'rechargeList',
                component: () => import('../components/CoursePayment/rechargeList.vue'),
            },
            // 版本更新配置--更新配置
            {
                path: 'updateConfiguration',
                component: () => import('../components/VersionUpdate/updateConfiguration.vue'),
            },
            // 更新日志
            {
                path: 'updateLogs',
                component: () => import('../components/VersionUpdate/updateLogs.vue'),
            },
            // 消息推送
            {
                path: 'MessagePush',
                component: () => import('../components/MessagePush/messageList.vue'),
            },
            // 消息编辑
            {
                path: 'messageEdit',
                component: () => import('../components/MessagePush/components/messageEdit.vue'),
            },
            // 发布消息数据
            {
                path: 'messageData',
                component: () => import('../components/MessagePush/components/messageData.vue'),
            },
            // 问卷调查
            {
                path: 'QuestionnaireInvestigation',
                component: () => import('../components/QuestionnaireInvestigation/questionnaireList.vue'),
            },
            // 问卷调查数据
            {
                path: 'questionnaireData',
                component: () => import('../components/QuestionnaireInvestigation/components/questionnaireData.vue'),
            },
            // 新增问卷调查
            {
                path: 'addQuestionnaire',
                component: () => import('../components/QuestionnaireInvestigation/addQuestionnaire.vue'),
            },
        ]
    },
    {
        path: '/404',
        name: '404',
        component: () => import('../views/404.vue')

    },
    {
        path: '/:pathMatch(.*)',
        redirect: '/404'
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
