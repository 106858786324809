import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from "vue-cookies"
import smart from 'address-smart-parse'
import installElementPlus from './plugins/element'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import print from 'vue3-print-nb'
import ModalHelper from './utils/modalHelper'
import axios from 'axios'
import VueClipBoard from 'vue-clipboard2'

const app = createApp(App)
import dir from "./directive/index";
app.directive("myOneClick", dir.my_one_click);
app.directive("myInput", dir.myInput);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
installElementPlus(app)

let baseURL = '/api'
if (process.env.NODE_ENV !== "development") {
    baseURL = ''
}

axios.defaults.baseURL = baseURL
axios.defaults.withCredentials = true;
// 设置全局的token
axios.defaults.headers.common['laytp-admin-token'] = window.localStorage.getItem("token");
app.config.globalProperties.$axios = axios;
axios.defaults.timeout = 60000;
app.config.globalProperties.$smart = smart
app.config.globalProperties.$cookies = VueCookies
app.config.globalProperties.$modalHelper = ModalHelper
app.config.globalProperties.exportUrl = ""
app.use(store).use(print).use(router).use(VueClipBoard).mixin({
    data() {
        return { baseURL }
    }
}).mount('#app')